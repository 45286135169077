<template>
  <div>
    <!-- Header -->
    <div class="header bg-primary py-6 py-lg-6 pt-lg-6">
      <b-container>
        <div class="header-body text-center mb-1">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5 mb-2">
              <img style="width:100px;" src="@/assets/img/zulal.svg" class=" border-0  bg-primary navbar-brand-img" alt="QNL logo">

            </b-col>
          </b-row>
        </div>
      </b-container>

    </div>
    <!-- Page content -->
    <b-container class="mt--6 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-light border-0 mb-0">

            <b-card-body class="px-lg-5 py-lg-5">

              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Username"
                              :rules="{required: true}"
                              prepend-icon="ni ni-single-02"
                              placeholder="Username"
                              v-model="model.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                <!--  <b-form-checkbox v-model="model.rememberMe">Remember me</b-form-checkbox> -->
                  <div class="text-center">
                    <base-button :loading="userStore.state.busy" type="primary" native-type="submit" class="my-4">
                      Sign in
                    </base-button>
                  </div>


                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
         
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import {handleError} from "@/util/error-handler"
  export default {
    inject: ["userStore"],
    data() {
      return {
        model: {
          username: '',
          password: '',
          rememberMe: false
        }
      };
    },
    
    methods: {
generateBreadCrumb() {
        return [{label: "media"}];
      },
      async onSubmit() {
        try {
          await this.userStore.logIn(this.model.username, this.model.password);
          //this.userStore.loggedIn(result);
          this.$router.replace({ path: '/Dashboard' })
        } catch (e) {
          handleError(e)
        }
      }
    }
  };
</script>
